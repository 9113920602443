/* eslint-disable */

import React from "react";

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form } from "react-bootstrap-v5";

import { FormErrorMessage, InputErrorMessage } from "./ErrorMessages.js";
import { addPatient,getBotExtension,addExtenstionUser } from "../apis/index.js";
import Main from "./Main.js";
import { patientValidationSchema } from "./Validation.js";

import { getChatPatient, getUserSession, setChatPatient, setUserSession,getExtensionSession,setExtensionSession } from "./utils/HelperFunction.js";
import axios from "axios";
import { API_BASE_URL } from "./utils/BaseUrl.js";
import { REACT_APP_IMAGE_BUCKET_ENDPOINT } from "./utils/BaseUrl.js";
import Linkify from "react-linkify";
import ChatExtensionView from "./ChatView.js";

const ChatModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({})
  const [showChat, setShowChat] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [botLoading,setBotLoading] = useState(true)
  const [isChecked, setIsChecked] = useState(false);
  const [roomId, setRoomId] = useState(null);
  const [clinicId,setClinicId] =useState(null)
  const [selectedClinic,setSelectedClinic] = useState({name:null,id:null})
  const [userDetails,setuserDetails] = useState({patientPhone:"",patientEmail:"",patientZip:"",patientName:""})
  const [toogleSmg,setToogleSmg] = useState(Boolean(!getUserSession()))
  const [tooltipText,setTooltipText] = useState("")
  const [hasSession,setHasSession] = useState(false)
  const [hasShortCode,setHasShortCode] = useState(true)

  const formik = useFormik({
    initialValues: {
      patientName: "",
      email: "",
      phoneNumber: "",
      zipCode: "",
      clinicId: null,
    },
    validationSchema: patientValidationSchema,
    onSubmit: async () => {
      if (!loading) {
        setLoading(true);
        try {
          await addPatient(formik?.values).then(async (res) => {
            if (res?.status === 200) {
              setuserDetails({
                ...userDetails,
                patientEmail:formik?.values?.email,
                patientPhone:formik?.values?.phoneNumber,
                patientZip:formik?.values?.zipCode,
                patientName:formik?.values?.patientName,
              })
              const sessionDetails = {
                ...formik?.values,
                roomId:res?.data?.data,showChat:true,showModal:true
              }
              const stringdata = JSON.stringify({...sessionDetails})
              setUserSession(stringdata);
              setRoomId(res?.data?.data);
              setShowChat(true);
              setLoading(false);
              formik.resetForm();
              if (isChecked) {
                setChatPatient(JSON.stringify(formik?.values));
              }
            }
          });
        } catch (err) {
          const message =
            err.response?.data?.message || error.response.statusText;
          setLoading(false);
          setError(message);
        }
      }
    },
  });

  const handleChange = (e) => {
    setError("");
    if (e.target.name == "phoneNumber") {
      if (e.target.value.length <= 10) {
        if(!isNaN(e?.target?.value)){
          formik?.handleChange(e);
        }
      }
    }else if(e.target.name == "zipCode"){
      if (e.target.value.length <= 5) {
        formik?.handleChange(e);
      }}else {
      formik?.handleChange(e);
    }
  };

  const handleChat = () => {
    setToogleSmg(false)
    setTooltipText("")
    setShowModal(true);
    const data = getUserSession()
    if(data){
      setUserSession(JSON.stringify({...JSON.parse(data),showModal:true}))
    }
  };

  const handleChatClose = () => {
    setShowModal(false);
     const data = getUserSession()
    if(data){
      setUserSession(JSON.stringify({...JSON.parse(data),showModal:false}))
    }
  };


  const getBusinessData = async (key) => {
    await axios.get(`${API_BASE_URL}/clinic/saa_chat_extension?chatApiKey=${key}`)
      .then(res => {
        setData(res.data?.data)

        if(res?.data?.data?.clinicData && res?.data?.data?.clinicData.length==1 ){
          formik.setValues((values)=>({
            ...values,
            clinicId:res?.data?.data?.clinicData[0]?.id
          }))
          handleTooltip(res.data?.data?.businessData?.tooltipText,res.data?.data?.businessData?.tooltipTimer)
          setSelectedClinic({name:res?.data?.data?.clinicData[0]?.name,id:res?.data?.data?.clinicData[0]?.id})
          const data = getUserSession();
          if (data) {
            const exist = JSON.parse(data);
            console.log(exist);
            if(exist?.roomId){
              formik.values.patientName = exist?.patientName;
              formik.values.phoneNumber = exist?.phoneNumber;
              formik.values.email = exist?.email;
              formik.values.zipCode = exist?.zipCode;
              setRoomId(exist?.roomId);
              setShowChat(true);
              setShowModal(exist?.showModal)
              setHasSession(true)
              setBotLoading(false)
            }
          }
          
        }

        if(res.data?.data?.businessData?.isPriorInfo && !window?.shortCode){
          setBotLoading(false)
        }
        
      if(window?.shortCode=="yekaterin_AI"){
        setShowChat(true)
        setShowModal(true)
      }
      })
  }

const addBotExtensionUser =async(clinicId)=>{
  try {
    if(clinicId){
      const res = await addExtenstionUser(clinicId)
      if(res?.status==200 && res?.data?.data){
        const data = res?.data?.data
        let user ={
          patientEmail:data?.user?.email,
          patientPhone:data?.user?.phoneNumber,
          patientZip:data?.user?.zipCode,
          patientName:data?.user?.patientName,
        }
        setuserDetails(user)
        setRoomId(data?.roomId);
        // setExtensionSession(JSON.stringify({...user,roomId:data?.roomId}))
        setUserSession(JSON.stringify({...user,roomId:data?.roomId}));
        setShowChat(true);
        setShowModal(true)
        setBotLoading(false)
      }
    }
  } catch (error) {
    console.log(error);
  }
}

useEffect(async()=>{
  const session = getUserSession();
  if(!session){
    if(window?.shortCode=="yekaterin_AI" || !data?.businessData?.isPriorInfo){
      await addBotExtensionUser(data?.clinicData[0]?.id)
    }
  }
},[showModal])


  const getBotExtensionData = async(key)=>{
      try {
        const res =  await getBotExtension(key)
        if(res?.status==200 && res?.data?.data){
          const resultData =  res?.data?.data
          setData(resultData)
          setSelectedClinic({name:resultData?.clinicData?.name,id:resultData?.clinicData?.id})
            const data = getExtensionSession()
            const jsonData = JSON.parse(data)
            if(jsonData?.roomId){
              setRoomId(jsonData?.roomId);
              setuserDetails({
                patientEmail:jsonData?.patientEmail,
                patientPhone:jsonData?.patientPhone,
                patientZip:jsonData.patientZip,
                patientName:jsonData.patientName,
              })
              setShowModal(true)
              setShowChat(true);
              setHasSession(true)
          }else{
            addBotExtensionUser(resultData?.clinicData?.id)
          }
        }
      } catch (error) {
        console.log(error);
      }
  }

 const handleTooltip = (text,timer)=>{
  if(toogleSmg && Number(timer)){
    setTooltipText(text)
    setTimeout(() => {
      setTooltipText("")
    }, timer*1000);
  }
 }

  useEffect(() => {

      setuserDetails({patientPhone:"",patientEmail:"",patientZip:"",patientName:""})
      //  window.chatApiKey ="5b8f7127-6a46-464d-a43f-c683f0dd5361dowAIeZettMHSPe" // testing
      // window.chatApiKey ="b799f32e-6eb3-4a31-9aa6-b1bdf138993dgOYNATdZmwHoLcs"
      // window.chatApiKey ="df396b53-4644-47b4-adce-3045c90b1318FSpCnIOzMUqDfon" // testing
      // window.chatApiKey ="13502cd2-c76d-4468-b681-6d0fb086c7a6EzIwuiGVgsGHrVP"

      // instance
      // window.chatApiKey = "ac32cbff-3678-4cc7-9896-95ac9dc979a9nxKzxHtJywJZmBG"
      // window.shortCode="yekaterin_AI"
      if (window?.chatApiKey) {
        // if(window?.shortCode=="yekaterin_AI"){
        //   getBotExtensionData(window?.chatApiKey)
        // }else{
          const data = getChatPatient();
          // setHasShortCode(false)
          if (data) {
            const exist = JSON.parse(getChatPatient());
            formik.values.patientName = exist.patientName;
            formik.values.phoneNumber = exist.phoneNumber;
            formik.values.email = exist.email;
            formik.values.zipCode = exist?.zipCode;
            // setIsChecked(true);
          }
          getBusinessData(window?.chatApiKey);
        // }
      
      }

  }, []);

  

  const btnDisabled = !formik.values.clinicId ||!formik.values.email||!formik.values.patientName ||!formik.values.phoneNumber
 
  return (
      <>
      {window?.shortCode=="yekaterin_AI" && data?.businessData?.id ? <>
        {
          <ChatExtensionView
            clinicId={selectedClinic.id}
            clinicName={selectedClinic?.name}
            roomId={roomId}
            setShowChat={setShowChat}
            showChat={showChat}
            data={data}
            userDetails={userDetails}
            showModal={showModal}
            hasSession={hasSession}
            botLoading={botLoading}
          />
        }
      </> :
      <>
        <Main
        clinicId={selectedClinic.id}
        clinicName={selectedClinic?.name}
        roomId={roomId}
        setShowChat={setShowChat}
        showChat={showChat}
        data={data}
        userDetails={userDetails}
        showModal={showModal}
        hasSession={hasSession}
        botLoading={botLoading}

        >
          {!showChat && !botLoading ? <div className="w-max-500px bg-body rounded py-4 px-4  mx-auto">
            <form
              className="form w-100 form-inner-chat"
              onSubmit={formik.handleSubmit}
            >
              <div className="text-center mb-2">
                <h1 className="mb-2">Start-Conversation</h1>
                <div className="text-gray-600 fw-normal fs-6">
                  Please enter your details to start chat.
                </div>
              </div>
              <div className="mb-3">
                <input
                  className={`form-control form-control-lg form-control-solid ${formik.touched.patientName &&
                    Boolean(formik.errors.patientName)
                    ? "error"
                    : ""
                    }`}
                  value={formik.values.patientName}
                  onChange={handleChange}
                  name="patientName"
                  id="patientName"
                  type="text"
                  autoComplete="off"
                  placeholder="Name"
                />
                <InputErrorMessage
                  error={
                    formik.touched.patientName && formik.errors.patientName
                  }
                  marginBottom={-15}
                />
              </div>
              <div className="mb-3">
                <input hidden type="email" name="email" />
                <input
                  className={`form-control form-control-lg form-control-solid ${formik.touched.email && Boolean(formik.errors.email)
                    ? "error"
                    : ""
                    }`}
                  type="email"
                  name="email"
                  id="email"
                  value={formik.values.email}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Email address"
                />
                <InputErrorMessage
                  error={formik.touched.email && formik.errors.email}
                  marginBottom={-15}
                />
              </div>
              <div className="mb-3">
                <input
                  className={`form-control form-control-lg form-control-solid ${formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                    ? "error"
                    : ""
                    }`}
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={handleChange}
                  autoComplete="off"
                  placeholder="Phone Number"
                />
                <InputErrorMessage
                  error={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  marginBottom={-15}
                />
              </div>
           
              {/* <div className="mb-3">
                <input
                  className={`form-control form-control-lg form-control-solid ${formik.touched.zipCode &&
                    Boolean(formik.errors.zipCode)
                    ? "error"
                    : ""
                    }`}
                  value={formik.values.zipCode}
                  onChange={handleChange}
                  name="zipCode"
                  id="zipCode"
                  type="number"
                  autoComplete="off"
                  placeholder="Zip code"
                />
                <InputErrorMessage
                  error={
                    formik.touched.zipCode && formik.errors.zipCode
                  }
                  marginBottom={-15}
                />
              </div> */}
              <div className="text-center">
                <FormErrorMessage
                  error={error}
                  marginTop={-10}
                  marginBottom={15}
                />

                <button  type="submit"
                  //disabled={loading}
                  disabled={btnDisabled || loading}
                  id="kt_sign_in_submit"
                  className={`btn-dark btn-lg bg-purple btn ${btnDisabled && "disabled"} w-100 fs-16 font-weight-500 mb-4`}
                  style={{ border: "none" }}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  ) : (
                    "Continue"
                  )}
                </button>
              </div>
            </form>
          </div> :
          <>
          {botLoading &&  <div className="d-flex gap-2 w-max-500px scroll-div align-items-center justify-content-center">
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow spinner-grow-sm bg-purple" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
          </div>}
          </>
        }
        </Main>
      {data?.businessData?.chatStatus && (
        <>
          {tooltipText && <div className="h-tooltip">{tooltipText}</div>}
         {toogleSmg && <div className="h-smg"><span style={{display:"flex",alignItems:"center",justifyContent:"center"}}>1</span></div>} 
         {data?.businessData?.botImg ?
         <img  onClick={() => (!showModal ? handleChat() : handleChatClose())} className="d-hotline h-btn animated zoomIn faster border img-fluid" src={`${REACT_APP_IMAGE_BUCKET_ENDPOINT}/${encodeURIComponent(data?.businessData?.botImg)}`}/>
         :
         <div
         id="chat-modal"
         className="d-hotline h-btn animated zoomIn faster"
         onClick={() => (!showModal ? handleChat() : handleChatClose())} >
         <div id="chat-icon" aria-label="Chat"></div>
         <div className=" ">
           <span aria-hidden="true"></span>
         </div>
       </div>
        }
 
        </>
      )}
      </>
      }
    </>
  );
};

export default ChatModal;
